import React from "react";
import PropTypes from "prop-types";
import { Card, Typography, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const StatsCard = (props: any) => {
  const { loading, title, tooltip, child } = props;

  return (
    <Card
      size="small"
      style={{ minWidth: 200, margin: 8 }}
      loading={loading}
      extra={
        <Tooltip title={tooltip}>
          <InfoCircleOutlined />
        </Tooltip>
      }
      title={<Typography.Text>{title}</Typography.Text>}
    >
      {!loading ? props.children : ""}
    </Card>
  );
};

StatsCard.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  //   child: PropTypes.object.isRequired,
} as any;

export default StatsCard;
