const config = {
    "middlewareUrl":"https://middleware.storage.sis.lt", // "http://localhost:8080",
    "fileStorageUrl": "https://api.storage.sis.lt/v1",
    "countries": [
        {
            "key": "lt",
            "name": "Lithuania",
            "apiUrl": "https://api.lt.playground.ecmr4.eu/v1",
            "logo64": "/img/flags/lt-flag-64.png",
            "sso": {
                "url": "https://sso.playground.ecmr4.eu",
                "realm": "lt"
            }
        },
        {
            "key": "pl",
            "name": "Poland",
            "apiUrl": "https://api.pl.playground.ecmr4.eu/v1",
            "logo64": "/img/flags/pl-flag-64.png",
            "sso": {
                "url": "https://sso.playground.ecmr4.eu",
                "realm": "pl"
            }
        },
        {
            "key": "ee",
            "name": "Estonia",
            "apiUrl": "https://api.ee.playground.ecmr4.eu/v1",
            "logo64": "/img/flags/ee-flag-64.png",
            "sso": {
                "url": "https://sso.playground.ecmr4.eu",
                "realm": "ee"
            }
        },
        {
            "key": "lv",
            "name": "Latvia",
            "apiUrl": "https://api.lv.playground.ecmr4.eu/v1",
            "logo64": "/img/flags/lv-flag-64.png",
            "sso": {
                "url": "https://sso.playground.ecmr4.eu",
                "realm": "lv"
            }
        },
        {
            "key": "ua",
            "name": "Ukraine",
            "apiUrl": "https://api.ua.playground.ecmr4.eu/v1",
            "logo64": "/img/flags/ua-flag-64.png",
            "sso": {
                "url": "https://sso.playground.ecmr4.eu",
                "realm": "ua"
            }
        },
        {
            "key": "ru",
            "name": "Russia",
            "apiUrl": "https://api.ru.playground.ecmr4.eu/v1",
            "logo64": "/img/flags/ru-flag-64.png",
            "sso": {
                "url": "https://sso.playground.ecmr4.eu",
                "realm": "ru"
            }
        },
        // {
        //     "key": "by",
        //     "name": "Belarus",
        //     "apiUrl": "https://api.by.playground.ecmr4.eu/v1",
        //     "logo64": "/img/flags/by-flag-64.png",
        //     "sso": {
        //         "url": "https://sso.playground.ecmr4.eu",
        //         "realm": "by"
        //     }
        // },
        {
            "key": "lux",
            "name": "Benelux",
            "apiUrl": "https://api.lux.playground.ecmr4.eu/v1",
            "logo64": "/img/flags/lux-flag-64.png",
            "sso": {
                "url": "https://sso.playground.ecmr4.eu",
                "realm": "lux"
            }
        },
        {
            "key": "observer",
            "name": "Observer",
            "apiUrl": "https://api.observer.playground.ecmr4.eu/v1",
            "logo64": "/img/flags/observer-flag-64.png",
            "sso": {
                "url": "https://sso.playground.ecmr4.eu",
                "realm": "observer"
            }
        }
    ]
}
export default config
