import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./views/pages/Home/Home";
import Login from "./views/pages/Login/Login";
import PrivateRoute from "./views/components/PrivateRoute";
import "./App.scss";
import { getCurrentUserInfo } from "./services/indexRecord/indexRecord";
import { logOut } from "./store/modules/authentication/actions";

class App extends Component {
  componentDidMount() {
    const { exp } = getCurrentUserInfo()
    if (exp * 1000 < Date.now()) {
      logOut(true);
    }
  }
  render() {
    return (
      <Router>
        <Switch>
          <PrivateRoute exact path="/" component={Home} />
          <Route path="/login" component={Login} />
          {/* <Redirect from="*" to="/" /> */}
        </Switch>
      </Router>
    );
  }
}

export default App;
