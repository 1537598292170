import React, { useState } from "react";
import {
  Input,
  Spin,
  Modal,
  message,
  Dropdown,
  Form,
  Menu,
  Divider,
  Typography,
  Checkbox,
} from 'antd';
import './index.scss';
import { createFileAccess } from '../../../../../services/cloudStorage';
import { connect } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import { getAccessOptions } from "../../../../utils/fileAccess";
import Title from "antd/lib/typography/Title";
import Paragraph from "antd/lib/typography/Paragraph";
import Link from "antd/lib/typography/Link";

const ModalAddUser = (props) => {
  const [selectedAccessType, setSelectedAccessType] = useState('view')
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [form] = Form.useForm();
  const [notifyUser, setNotifyUser] = useState(false);

  const getManagableAccess = (type, userId?: string) => {
    const access = props.items
      .filter(({ policies }) => {
        return policies
          .filter(({ resources }) => !!resources
            .find((resource) => (resource.indexOf(type) > -1 ||
              resource.indexOf('<.*>') > -1))).length > 0
      })
      .map(({ policies, userID }) => {
        return {
          userID,
          actions: policies
            .filter(({ resources }) => !!resources
              .find((resource) => (resource.indexOf(type) > -1 ||
                resource.indexOf('<.*>') > -1)))
            .flatMap(({ actions }) => actions),
          accessActions: policies
            .filter(({ resources }) => !!resources
              .find((resource) => (resource.indexOf('access') > -1 ||
                resource.indexOf('<.*>') > -1)))
            .flatMap(({ actions }) => actions),
          allUserAccess: !userId && userID ? {
            metadata: getManagableAccess("metadata", userID),
            content: getManagableAccess("content", userID),
            access: getManagableAccess("access", userID)
          } : {
            metadata: {
              accessActions: [],
              actions: [],
              allUserAccess: {},
            },
            content: {
              accessActions: [],
              actions: [],
              allUserAccess: {},
            },
            access: {
              accessActions: [],
              actions: [],
              allUserAccess: {},
            },
          }
        }
      })
      .filter(({ userID }) => (userId === userID || userId == null))

    if (userId) {
      return access.length ? access[0] : { actions: [] }
    }
    return access
  }

  const accessOptions = getAccessOptions(props.items && props.items[0] ? props.items[0].userID : '')

  const addUser = async ({ userId }) => {
    const newUserAccess: any = accessOptions
      .find(({ key }) => key === selectedAccessType)

    setIsAddingUser(true)
    const { uri } = props.document
    const [fileId] = uri
      .split('/')
      .slice(-1)

    const newAccess = {
      indexId: props.indexId,
      country: props.indexCountry,
      userID: userId.trim(),
      "policies": newUserAccess.access
        .map((item) => ({
          "description": `${item.source} ${item.type} policy`,
          "actions": item.actions,
          "resources": item.scope ? item.scope
            .map(scopeItem => `sis:storage:files:${fileId}:${item.source}:${scopeItem}`) :
            [
              `sis:storage:files:${fileId}:${item.source}`
            ],
          "effect": item.type
        }))
    }

    try {
      // TODO: refactor createFileAccess with redux
      const fileAccess = await createFileAccess(fileId, newAccess)
      message.success({
        content: "File access successfully added",
        duration: 3,
      });
      const { items } = props
      props.setItems([
        ...items,
        fileAccess.data
      ])

      props.document.access = [
        ...items,
        fileAccess.data
      ]
    } catch(err) {
      console.log(err);
    } finally {
      closeAddUserModal()
      setIsAddingUser(false)
      props.setIsAddUserModalVisible(false)
      setSelectedAccessType('view')
    }
  }

  const closeAddUserModal = () => {
    props.setIsAddUserModalVisible(false)
    const element: any = document!.getElementsByTagName("BODY")[0]
    element.style = ''
  }

  const selectAccessType = (e) => {
    setSelectedAccessType(e.key)
  }

  const getCurrentUserAccessType = (): any => {
    return accessOptions.find(({ key }) => selectedAccessType === key) || {}
  }
  const onChangeEmailText = (text) => {

  }

  const menu = (
    <Menu onClick={selectAccessType}>
      {
        accessOptions.map(({ key, label, icon }) => (
          <Menu.Item key={key} icon={icon}>
            {label}
          </Menu.Item>
        ))
      }
    </Menu>
  );

  return (
    <Modal
      title="Add permissions"
      width={800}
      visible={props.isAddUserModalVisible}
      onOk={() => form.submit()}
      onCancel={closeAddUserModal}
    >
      <div className="addUserModal">
        <div className="addUserInput">
          <Form
            style={{ width: "100%" }}
            onFinish={addUser}
            form={form}
          >
            <Form.Item
              name="userId"
              // rules={[
              //   { required: true },
              //   {
              //     pattern: new RegExp("^\\s*[a-zA-Z0-9*:]+\\s*$"),
              //     message: "Format is wrong"
              //   },
              // ]}
            >
              <Input name="userId" placeholder="Identificator" />
            </Form.Item>
          </Form>
          <Dropdown.Button
            overlay={menu}
            icon={getCurrentUserAccessType().icon} />
        </div>
        <div>
        {/* <div className="notifyUser">
            Other email <Checkbox
              onChange={(e) => setNotifyUser(!e.target.value)}
              value={notifyUser} />
          </div>
          {
            notifyUser &&
            <Input onChange={onChangeEmailText} placeholder="Email" />
          }
        </div>
        <div className="accessMessage">
          <div className="notifyUser">
            Add note  <Checkbox
              onChange={(e) => setNotifyUser(!e.target.value)}
              value={notifyUser} />
          </div>
          {
            notifyUser &&
            <TextArea onChange={onChangeEmailText} placeholder="Note" />
          } */}
          <Divider />
          <Typography>
            <Paragraph>
            <ul>
              <li>
                <Typography>
                  <b>{"<"}user-id{">"}</b> - Give Permission to a specific user. (Sample: <Typography style={{display: 'inline-block', color: 'red'}}>00000000-0000-0000-0000-000000000000</Typography>)
                </Typography>
              </li>
              <li>
                <b>{"<"}*{">"}</b> - Give Permission to all users.
              </li>
              <li>
                <Typography>
                  <b>{"<"}authority-icd{">"}:{"<"}organization-id{">"}</b> - Give Permission to specific organization. (Sample: <Typography style={{display: 'inline-block', color: 'red'}}>0200:188659752</Typography>)
                </Typography>
              </li>
              <li>
              <Typography>
                <b>{"<"}country{">"}:{"<"}member-type{">"}</b> - Give Permission to specific member type in a speicifc country. (Sample: <Typography style={{display: 'inline-block', color: 'red'}}>lt:government</Typography>)
              </Typography>
              </li>
              <li>
                <Typography>
                  <b>{"<"}*{">"}:{"<"}member-type{">"}</b> - Give Permission to all countries with a specific member type. (Sample: <Typography style={{display: 'inline-block', color: 'red'}}>*:government</Typography>)
                </Typography>
              </li>
            </ul>
          </Paragraph>

          </Typography>
        </div>
        {
          isAddingUser &&
          <div className="accessSpinner">
            <Spin tip={"Adding user..."} size={"small"} />
          </div>
        }
      </div>
    </Modal>
  );
}

export default connect()(ModalAddUser);