import axios from "axios";
import { getCountryAPIURLByKey } from "../config";
import { logOut } from "../store/modules/authentication/actions";

axios.interceptors.response.use(
  (response) => response,
  (error) => {

    if(!error.response)  return Promise.reject(error);

    const { status } = error.response;
    if (status === 401) {
      logOut(true);
    }
    return Promise.reject(error);
  }
);

export const getBlockchainInfo = async (country) => {
  const apiUrl = await getCountryAPIURLByKey(country)
  
  return await axios.get(
    `${apiUrl}/blockchain/info`,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
      // crossDomain: true,
    }
  );
};

export const getBlockInfo = async (country, blockNumber) => {
  const apiUrl = await getCountryAPIURLByKey(country)

  return await axios.get(
    `${apiUrl}/blockchain/block/${blockNumber}`,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
      // crossDomain: true,
    }
  );
};
