import { store } from "../../store";
import { addDocument, updateDocument, documentsStorageUrl } from '../document';
import { instance } from "./instance";

export const findIndex = async (
  country,
  transportRegNumber,
  transportCountryCode
) => {

  return instance.get(
    `/index?transport_reg_number=${transportRegNumber}&transport_country_code=${transportCountryCode.toUpperCase()}&country=${country}` +
    "&" + getLocationMetadata()
  );
};

export const getIndex = async (country, id, history = false) => {
  return instance.get(
    `/index/${id}?history=${history}&country=${country}` +
    "&" +
    getLocationMetadata(),
  );
};

export const getIndexLog = async (country, id) => instance
  .get(`/index/${id}/log?country=${country}`);

export const addIndex = async (country, index) => {
  const newDocument = await addDocument(index);

  index.document.uri = `${documentsStorageUrl}/${newDocument.data.id}`

  return instance.post(
    `/index?country=${country}` +
    "&" +
    getLocationMetadata(),
    index
  );
};

export const updateIndex = async (country, index) => {
  const updatedDocument = await updateDocument(index);
  if (updatedDocument) {
    index.document.uri = `${documentsStorageUrl}/${updatedDocument.data.id}`
  }
  return instance.put(
    `/index/${index.documentIdentifier.value}?country=${country}` +
    "&" +
    getLocationMetadata(),
    index
  );
};

const getLocationMetadata = () => {
  const state = store.getState();
  if (state.loginReducer && state.loginReducer.location) {
    return `longitude=${state.loginReducer.location.longitude}&latitude=${state.loginReducer.location.latitude}`;
  }
  return "";
};

export const getCurrentUserId = () => {
  const state = store.getState();
  if (!state.loginReducer) return null
  return state.loginReducer.id
}

export const getCurrentUserInfo = () => {
  const state = store.getState();
  if (!state.loginReducer) return null

  return state.loginReducer
}
