import React from "react";
import './Login.scss';
import { Redirect } from "react-router-dom";
import {
  Row,
  Typography,
  Tabs,
} from "antd";
import { connect } from "react-redux";
import { authenticate, storeAccessToken } from "../../../store/modules/authentication/actions";
import config from '../../../config'
import TabContentLoginIsense from "./components/TabContentLoginIsense";
import TabContentLoginDokobit from "./components/TabContentLoginDokobit";
import TabContentLoginBasic from "./components/TabContentLoginBasic";

const { TabPane } = Tabs;
const { Title } = Typography;

const centered = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const Login = (props) => {
  const renderLoginForm = () => {
    return (
      <div>
        <Row style={centered} >
          <Title style={{
            width: "100%",
            textAlign: 'center',
            marginTop: '1rem'
          }}
            level={2}>
            Login
          </Title>
          <img
            style={{ width: "100%", maxWidth: "20rem", padding: "2rem" }}
            src="/img/login.svg" />
        </Row>
        <Tabs style={{ paddingBottom: '2rem' }} type="card">
          <TabPane tab="Basic" key="1">
            <TabContentLoginBasic />
          </TabPane>
          {
            config.useDokobit &&
            <TabPane forceRender tab="Dokobit" key="2">
              <TabContentLoginDokobit />
            </TabPane>
          }
          {
            config.useIsenseLogin &&
            <TabPane forceRender tab="eID" key="3">
              <TabContentLoginIsense />
            </TabPane>
          }
        </Tabs>
      </div >
    );
  }

  const { isLoggedIn } = props as any

  return isLoggedIn ? (
    <Redirect from="/login" exact to={`${window.location.search}`} />
  ) : (
    <div
      className="Login"
    >
      <div className="card" >
        {renderLoginForm()}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.loginReducer.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  authenticate: (country, username, password) =>
    dispatch(authenticate(country, username, password)),
  storeAccessToken: storeAccessToken(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
