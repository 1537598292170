import { instance } from "./index";
import { createInstance } from "./instance";
import config from "../../config"

export interface IFileBody {
    file: any;
    name?: string;
    type?: string;
    format?: string;
    notes?: string;
}

export const createFile = (body: IFileBody) => {
    const { file, name, type, format } = body;
    const form = new FormData();
    form.append('file', file, name);
    form.append('type', type)
    form.append('format', format)
    const requestInstance = createInstance(`${config.fileStorageUrl}`)
    return requestInstance.post(`/files`, form);
}

export const updateFile = (fileID: string, body: IFileBody) => {
    return instance.put(`/files/${fileID}`, body);
}

export const getFile = (fileID: string, accessToken?: string) => {
    return instance.get(`/files/${fileID}${accessToken ? `?accessToken=${accessToken}` : ''}`);
}

export const getFileContent = (fileID: string, accessToken?: string) => {
    return instance.post(`/files/${fileID}/content${accessToken ? `?accessToken=${accessToken}` : ''}`);
}

export const getFileContentXslt = (fileID: string) => {
    return instance.get(`/files/${fileID}/transformation`);
}

export const validateXml = (fileID: string) => {
    return instance.get(`/files/${fileID}/validation`);
}

export const getAllFiles = () => {
    return instance.get(`/ files`);
}