import { prop } from 'rambda'
declare function require(name: string);

require('dotenv').config({ path: '../../.env' })

const {
    REACT_APP_PROJECT_COMMIT_DATE,
    REACT_APP_PROJECT_COMMIT_SHORT_SHA,
    REACT_APP_CONFIG_FILE,
    // REACT_APP_USE_DOKOBIT,
    // REACT_APP_USE_EID_LOGIN
} = process.env

// Dynamically read config file
const configFile = REACT_APP_CONFIG_FILE ? REACT_APP_CONFIG_FILE : "defaultConfig"
const configModule = require(`./${configFile}`);
const config = configModule.default

const envVariables = {
    projectCommitDate: REACT_APP_PROJECT_COMMIT_DATE ? REACT_APP_PROJECT_COMMIT_DATE : '',
    projectCommitShortSHA: REACT_APP_PROJECT_COMMIT_SHORT_SHA ? REACT_APP_PROJECT_COMMIT_SHORT_SHA : '',
    storageType: 1,
    // Config values
    countries: config["countries"],
    countriesKeys: config["countries"].map(prop('key')),
    middlewareUrl: config["middlewareUrl"],
    fileStorageUrl: config["fileStorageUrl"],
    useDokobit: true, // true ? REACT_APP_USE_DOKOBIT : false
    useIsenseLogin: false // REACT_APP_USE_EID_LOGIN
}

export default envVariables

export const getCountryByKey = (countryKey: string) => {
    return envVariables.countries.find(({ key }) => countryKey === key)
}

export const getCountryAPIURLByKey = (countryKey: string) => {
    return (envVariables.countries.find(({ key }) => countryKey === key) || {}).apiUrl
}
