import { getCurrentUserInfo } from "../indexRecord/indexRecord";
import { instance } from "./index";

export interface IAccessBody {
    userID: string;
    createdAt?: string;
    policies?: Array<any>;
    indexId?: string;
    country?: string;
}

export interface IRemoveAccessBody {
    userID: string;
    actions?: Array<string>;
    indexId?: string;
}

export interface IUpdateAccessBody {
    policies: any[]
    ndexKey?: string;
    indexId?: string;
    country?: string;
}

export const createFileAccess = (fileID: string, body: IAccessBody) => {
    return instance.post(`/files/${fileID}/access`, body);
}

export const getFileAccess = (fileID: string, includePolicies: boolean = true) => {
    return instance.get(`/files/${fileID}/access${includePolicies ? '?includePolicies=true' : ''}`);
}

export const deleteFileAccess = (fileID: string, userID: string, indexId: string, country: string) => {
    return instance.delete(`/files/${fileID}/access/${userID}?indexId=${indexId}&country=${country}`);
}

export const updateFileAccess = (fileID: string, userID: string, body: IUpdateAccessBody) => {
    return instance.put(`/files/${fileID}/access/${userID}`, body);
}

export const getAllAccessibleFiles = (lastModifiedBefore, lastModifiedAfter) => {
    const properties = { lastModifiedBefore, lastModifiedAfter }
    const params = Object.entries(properties)
        .filter(([_, value]) => !!value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')
    return instance.get(`/access/files${params ? `?${params}` : ''}`);
}