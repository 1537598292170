import axios from "axios";
import config from "../config";
import fileSaver from "../views/utils/fileSaver"
import { createFile } from "./cloudStorage";
import serverStorage from "./serverStorage"

//  window.location.hostname === 'localhost' ? 'http://localhost:8080' :

export enum StorageType {
    Server,
    Cloud,
}

export const documentsStorageUrl = config.storageType === StorageType.Cloud
    ? `${config.fileStorageUrl}/files`
    : `https://${window.location.hostname}/api/v1/document`

export const getDocument = (uri) => {
    if (config.storageType === StorageType.Cloud) {
        return getFileFromCloud(uri)
    }

    return getFileFromServer(uri)
}

export const getFileFromServer = async (uri) => {

    const document = await axios.get(
        uri,
        {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
            // crossDomain: true,
        }
    );

    const { fileName, fileContent } = document.data;
    fileSaver(new Blob([new Uint8Array(fileContent.data)]), fileName);
    return document;
}

export const getFileFromCloud = async (uri) => {
    const fileMeta = await axios.get(
        uri,
        {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
            // crossDomain: true,
        }
    );

    const fileContent = await axios.get(
        `${uri}/content`,
        {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
            responseType: 'blob',
        }
    );

    const { name } = fileMeta.data;

    fileSaver(new Blob([fileContent.data], { type: 'application/octet-stream' }), name);
    return fileMeta;
}

export const addDocument = async (index) => {
    const { document } = index;

    if (!document || !document.content) return null;

    const { data, name } = document.content
    const format = name.split('.').slice(-1)[0]
    if (config.storageType === StorageType.Cloud) {
        return createFile({ file: data, name, format })
    }
    return serverStorage.addDocument(index)
}

export const updateDocument = async (index) => {
    const { document } = index;

    if (!document || !document.content) return null;

    const { data, name, type } = document.content

    // Temporary commented until file_content_update does not exist

    // if (config.storageType === StorageType.Cloud) {
    //     return updateFile(document.fileId, { file: data, name, format })
    // }
    
    if (config.storageType === StorageType.Cloud) {
        return createFile({ file: data, name, type })
    }

    return serverStorage.updateDocument(index)
}
