import axios from "axios";
import qs from "qs";
import { getCountryByKey } from "../../../config/index"
import { ACTION_TYPES } from "../../consts";
import { parseJwt } from "../../../views/utils/jwt";
import { getCurrentPosition } from "../../../views/utils/location";
import { setAccessToken } from "../../../services/cloudStorage/instance";
import { Modal } from "antd";
import { addSingInLog } from "../../../services/logs";

const getClientIP = async () => {
  const { data } = await axios.get("https://www.cloudflare.com/cdn-cgi/trace")
  let ipRegex = /[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}/
  let ip = data.match(ipRegex)[0]
  return ip
}

export const authenticate = (country, username, password) => {
  return async (dispatch) => {
    try {
      const countryConfig = await getCountryByKey(country)
      const ssoInfo = countryConfig["sso"]
      const { data: { access_token, refresh_token } } = await axios.post(
        `${ssoInfo["url"]}/auth/realms/${ssoInfo["realm"]}/protocol/openid-connect/token`,
        qs.stringify({
          username: username,
          password: password,
          client_id: "index-api",
          grant_type: "password",
        }),
      );
      var jwt = parseJwt(access_token);
      const ip = await getClientIP()
      const singInLog = {
        userId: jwt.sub,
        ip,
        signInDate: new Date().toISOString()
      }
      addSingInLog(singInLog)
      storeAccessToken(dispatch)(access_token, refresh_token, country, username, password)
    } catch (err) {
      Modal.error({
        centered: true,
        title: "Login failed!",
        content: (
          err.response && err.response.data
            ? err.response.data.error_description
            : err.toString()
        ),
        // onOk: () => {
        //   window.location.reload()
        // }
      });
    }
  };
};

export const storeAccessToken = (dispatch) => (access_token, refresh_token, country, username, password) => {
  // localStorage.setItem("auth", response.data);
  localStorage.setItem("access_token", access_token);
  localStorage.setItem("refresh_token", refresh_token);
  setAccessToken(access_token);
  // console.log("ACCESS TOKEN: ", response.data.access_token);
  var jwt = parseJwt(access_token);

  getCurrentPosition(
    (response) => {
      if (response.type === "success") {
        dispatch(
          setLoginState({
            location: response.data,
          })
        );
      }
    },
    {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 0,
    }
  );
  dispatch(
    setLoginState({
      ...jwt,
      id: jwt.sub,
      fullName: `${jwt.given_name} ${jwt.family_name}`,
      username,
      password,
      country,
      isLoggedIn: true,
      memberType: jwt.ecmr.dlt.member_type,
    })
  );
}

export const logOut = (reload = true) => {
  return (dispatch) => {
    dispatch(setLoginState({ isLoggedIn: false }));
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("state");
    if (reload) {
      window.location.reload();
    }
  };
};

const setLoginState = (loginState) => {
  return {
    type: ACTION_TYPES.SET_LOGIN_STATE,
    payload: loginState,
  };
};