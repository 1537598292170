import thunkMiddleware from "redux-thunk";
import { createStore, combineReducers, applyMiddleware } from "redux";
// import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'; // this is for debugging with React-Native-Debugger, you may leave it out
import { loginReducer } from "./modules/authentication/reducers";
import documentIndexReducer from "./modules/documentIndex/reducers";
import { loadState } from "../views/utils/localStorage";

const rootReducer = combineReducers({
  loginReducer,
  documentIndexReducer
});

const persistedState = loadState();

export const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(thunkMiddleware)
);
