import React, { useState } from "react";
import {
  Drawer,
  Button,
} from 'antd';
import {
  UserAddOutlined
} from '@ant-design/icons';
import './index.scss';
import PermissionCell from '../PermissionCell'
import { connect } from 'react-redux';

// TODO: use function, not class
const EditableTagGroup = (props) => {
  const [isAccessPanelVisible, setIsAccessPanelVisible] = useState(false)

  const {
    document,
    currentUserAccess,
    items
  } = props

  const [fileId] = document.uri ? document.uri
    .split('/')
    .slice(-1)
    : []

  const dataAndActions = [
    {
      key: 'canCreateAccessPermissions',
      accessType: 'access',
      action: 'create'
    }
  ]

  const [
    canCreateAccessPermissions,
  ] = dataAndActions.map(item => currentUserAccess[item.accessType].accessActions &&
    (currentUserAccess[item.accessType].accessActions.indexOf(item.action) > -1 ||
      currentUserAccess[item.accessType].accessActions.indexOf('<.*>') > -1))

  return (
    <div>
      <a
        style={{ fontSize: "12px" }}
        onClick={() => setIsAccessPanelVisible(true)}>
        Manage access
      </a>
      <Drawer
        onClose={() => setIsAccessPanelVisible(false)}
        title={"Manage Access"}
        visible={isAccessPanelVisible}
        width={400}>
        <div className="AccessDrawer">
          {
            canCreateAccessPermissions &&
            <div className="addUser">
              <Button
                onClick={props.openAddUserModal}
                type="default"
                icon={<UserAddOutlined />}>
                Add permissions
              </Button>
            </div>
          }
          <div className="section" >
            <div className="manageAccessSection" >Has access</div>
            {
              items
                .map(item => (
                  <PermissionCell
                    indexId={props.indexId}
                    indexCountry={props.indexCountry}
                    key={item.userID}
                    item={item}
                    items={items}
                    fileId={fileId}
                    type={"metadata"}
                    removeAccess={props.removeAccess}
                    currentUserAccess={currentUserAccess.access} />
                ))
            }
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default connect()(EditableTagGroup);