const config = {
    "middlewareUrl": "https://middleware.index.sis.lt/api/storage/v1",
    "fileStorageUrl": "https://api.cloud.corposign.net/v1/storage",
    "countries": [
        {
            "key": "sis",
            "name": "Systems integration solutions, UAB",
            "apiUrl": "https://api.index.sis.lt/v1",
            "logo64": "/img/flags/lt-flag-64.png",
            "sso": {
                "url": "https://sso.playground.ecmr4.eu",
                "realm": "lt"
            }
        }
    ]
}
export default config