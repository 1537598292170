import React, { useEffect, useState } from "react";
import './Home.scss'
import {
  Col,
  Card,
  Tabs,
  Modal,
  Row,
  Button,
  Typography,
  Layout,
  message,
  Divider,
  Collapse,
} from "antd";

import config from '../../../config';
import { logOut } from "../../../store/modules/authentication/actions";
import { connect } from "react-redux";
import FindIndexTab from "../../components/FindIndexTab/FindIndexTab";
import GetIndexTab from "../../components/GetIndexTab";
import GetAccessLogTab from "../../components/GetAccessLogTab";
import { PlusOutlined } from "@ant-design/icons";
import IndexDrawerForm from "../../components/Drawers/IndexDrawerForm/IndexDrawerForm";
import { getBlockchainInfo, getBlockInfo } from "../../../services/blockchain";
import StatsCard from "../../components/StatsCard";
import NavigationBar from "../../components/NavigationBar";
import { addDocumentIndexAction, updateDocumentIndexAction } from "../../../store/modules/documentIndex/actions";
import { getFile, getFileAccess } from "../../../services/cloudStorage";
import FileDescription from "../../components/FileDescription";

const { Content, Footer } = Layout;
const { Panel } = Collapse

const Home = (props) => {
  const [timer, setTimer] = useState(null);
  const [addIndexVisible, setAddIndexVisible] = useState(false);
  const [creatingIndex, setCreatingIndex] = useState(false);
  const [blockchainInfo, setBlockchainInfo] = useState(null);
  const [transactionInfo, setTransactionInfo] = useState(null);
  const [document, setDocument] = useState(null);
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [indexDrawerData, setIndexDrawerData] = useState(null);

  useEffect(() => {
    const url = new URL(window.location.href)
    const documentId = url.searchParams.get('documentId')
    if (documentId) {
      getDocument(documentId)
    }
    fetchStats();
    setTimer(setInterval(() => fetchStats(), 30000));
    return () => {
      clearInterval(timer);
      setTimer(null);
    }
  }, []);


  const getDocument = async (documentId) => {
    try {
      setLoadingDocument(true)
      const access = await getFileAccess(documentId)
      const fileMeta = await getFile(documentId)
      setDocument({
        access: access.data,
        metaData: fileMeta.data
      })
      setLoadingDocument(false)
    } catch (err) {
      setLoadingDocument(false)
      console.log(err)
      Modal.error({
        centered: true,
        title: "Not found",
        content: <div>Document with id {documentId} not found</div>,
      });
    }
  }

  const fetchStats = async () => {
    if (props.login.memberType === "government") {
      try {
        const bcInfoResp = await getBlockchainInfo(props.login.country);
        var lastHeight = blockchainInfo
          ? blockchainInfo.height
          : -1;
        setBlockchainInfo(bcInfoResp.data)

        if (lastHeight !== bcInfoResp.data.height) {
          const resp = await getBlockInfo(
            props.login.country,
            bcInfoResp.data.height - 1
          );
          if (resp.status === 200) {
            var lastIndexes = [];
            resp.data.data.data.forEach((tx) => {
              var actions = tx.payload.data.actions;
              var indexes = [];
              actions.forEach((action) => {
                action.payload.action.proposal_response_payload.extension.results.ns_rwset.forEach(
                  (set) => {
                    var indexReads = set.rwset.reads.filter((read) =>
                      read.key.endsWith("-log")
                    );
                    if (indexReads.length > 0) {
                      indexReads = indexReads.map((read) =>
                        read.key.slice(0, read.key.length - 4)
                      );
                      indexes.push(...indexReads);
                    }
                  }
                );
              });
              lastIndexes.push(...indexes);
            });

            setTransactionInfo({
              transactions: resp.data.data.data,
              lastTransaction: {
                mspid:
                  resp.data.data.data[resp.data.data.data.length - 1].payload
                    .header.signature_header.creator.mspid,
                timestamp:
                  resp.data.data.data[resp.data.data.data.length - 1].payload
                    .header.channel_header.timestamp,
              },
              lastIndexes: lastIndexes,
            })
          }
        }
      } catch (err) {
        console.log("Failed to fecth stats: ", err);
      }
    }
  }

  const valuesToIndex = (values) => {
    return {
      participantIdentifier: {
        scheme: values.participantSchemeId,
        value:
          values.participantIdentifierIcd +
          ":" +
          values.participantIdentifierRegisterCode,
      },
      documentIdentifier: {
        scheme: {
          id: values.documentSchemeId,
          value: values.documentSchemeValue,
        },
        value: values.documentId,
      },
      processIdentifier: {
        scheme: values.processIdentifierScheme,
        value: values.processIdentifierValue,
      },
      document: {
        expiredDateTime: values.documentExpiredDateTime
          ? values.documentExpiredDateTime.toISOString()
          : null,
        transport: values.transport,
        countryCode: values.documentCountryCode,
        uri: values.documentUri,
        fingerprint: values.documentFingerprint,
        content: values.documentContent,
        access: values.documentAccess,
        fileId: values.fileId,
      },
    };
  };

  const openIndexDrawer = (index = null) => {
    setAddIndexVisible(true)
    setIndexDrawerData(index)
    return index
  };

  const updateCurrentIndex = async (values) => {
    setCreatingIndex(true)

    var index = valuesToIndex(values);

    try {
      await props.updateDocumentIndex(props.login.country, index)

      message.success({
        content: "Index successfully updated!",
        duration: 3,
      });
      setCreatingIndex(false)
      setAddIndexVisible(false)
    } catch (err) {
      setCreatingIndex(false)
      var error = err.toString();

      if (err.response && err.response.status === 401) {
        Modal.error({
          centered: true,
          title: "Session ended",
          content: <div>Your session has ended. Please login again.</div>,
          onOk: () => {
            window.location.reload();
          },
        });
        return;
      }

      if (err.response && err.response.data) {
        error = err.response.data.message ? err.response.data.message : err.response.data.response;
      }
      Modal.error({
        centered: true,
        title: "Index update failed!",
        content: <div>
          {
            err.response && err.response.data ?
              <Collapse className="errorExpandable">
                <Panel header={error} key="1">
                  <pre>
                    <code>
                      {JSON.stringify(err.response.data, null, 2)}
                    </code>
                  </pre>
                </Panel>
              </Collapse>
              :
              error
          }
        </div>,
        onOk: () => {
          setCreatingIndex(false)
          setAddIndexVisible(false)
        },
      });
      return;
    }
  };

  const submitNewIndex = async (values) => {
    setCreatingIndex(true)
    var index = valuesToIndex(values);
    try {
      await props.addDocumentIndex(props.login.country, index)
      message.success({
        content: "Index successfully created!",
        duration: 3,
      });
      setCreatingIndex(false)
      setAddIndexVisible(false)

      return
    } catch (err) {
      console.log(err)
      setCreatingIndex(false)
      var error = err.toString();

      if (err.response && err.response.status == 401) {
        Modal.error({
          centered: true,
          title: "Session ended",
          content: <div>Your session has ended. Please login again.</div>,
          onOk: () => {
            window.location.reload();
          },
        });
        return;
      }

      if (err.response && err.response.data) {
        error = err.response.data.message ? err.response.data.message : err.response.data.response;
      }
      Modal.error({
        centered: true,
        title: "Index creation failed!",
        content: <div>
          {
            err.response && err.response.data ?
              <Collapse className="errorExpandable">
                <Panel header={error} key="1">
                  <pre>
                    <code>
                      {JSON.stringify(err.response.data, null, 2)}
                    </code>
                  </pre>
                </Panel>
              </Collapse>
              :
              error
          }
        </div>,
        onOk: () => {
          setCreatingIndex(false)
          setAddIndexVisible(false)
        },
      });
      return;
    }
  };

  const getTabs = () => {
    var tabs = [
      <GetIndexTab
        tab="Get Index"
        key="get"
        withHistory={props.login.memberType === "government"}
        onEdit={
          props.login.memberType === "business"
            ? (data) => openIndexDrawer(data)
            : null
        }
      />,
    ];

    switch (props.login.memberType) {
      case "government":
        tabs.unshift(
          <FindIndexTab
            tab="Find Index"
            key="find"
            onEdit={(data) => openIndexDrawer(data)}
          />
        );
        tabs.push(<GetAccessLogTab tab="Get Access Log" key="log" />)
        break;
      case "business":
        break;
    }

    return tabs;
  };


  const closeDrawer = () => {
    // const element: any = document!.getElementsByTagName("BODY")[0]
    // element.style = ''
    setAddIndexVisible(false)
  }

  const url = new URL(window.location.href)
  const indexId = url.searchParams.get('indexId')
  const defaultTabKey = (props.login.memberType === "government" && indexId == null) ? 'find' : 'get'

  const stats = () => {
    if (props.login.memberType !== "government") {
      return <></>;
    }

    const txInfoLoading = transactionInfo == null,
      bcInfoLoading = blockchainInfo == null;

    return (
      <>
        <StatsCard
          loading={bcInfoLoading}
          title="Data blocks"
          tooltip="The total number of data blocks in blockchain network"
        >
          <Typography.Title level={3}>
            {blockchainInfo ? blockchainInfo.height : -1}
          </Typography.Title>
        </StatsCard>
        <StatsCard
          loading={txInfoLoading}
          title="Last Transaction"
          tooltip="General information about last transaction submitted to the network"
        >
          <Row justify="start" align="middle">
            <Typography.Title level={4}>
              {transactionInfo
                ? transactionInfo.lastTransaction.mspid
                : ""}
            </Typography.Title>
          </Row>
          <Divider dashed />
          <Row justify="start" align="middle">
            <Typography.Title level={4}>
              {transactionInfo
                ? (() => {
                  var ts = new Date(
                    transactionInfo.lastTransaction.timestamp
                  );
                  return (
                    ts.toLocaleDateString() + " " + ts.toLocaleTimeString()
                  );
                })()
                : ""}
            </Typography.Title>
          </Row>
        </StatsCard>
        <StatsCard
          loading={txInfoLoading}
          title="Last Indexes"
          tooltip="A list of all indexes that were read, created or updated in last transaction"
        >
          {transactionInfo
            ? transactionInfo.lastIndexes.map((index) => (
              <Row key={index}>
                <Col span={24}>
                  <Typography.Text code>{index}</Typography.Text>
                </Col>
              </Row>
            ))
            : ""}
        </StatsCard>
      </>
    );
  };

  return (
    <Layout className={"Home"}>
      <Layout className="site-layout">
        <NavigationBar />
        <Content style={{ margin: "0 16px" }}>
          <div className="site-layout-background" style={{ padding: 24 }}>
            <Row justify="center">
              {
                (document || loadingDocument) ?
                  <Col>
                    <Card className="mainCard" >
                      <Row justify="center">
                        <FileDescription
                          data={document}
                          loading={loadingDocument}
                        />
                      </Row>
                    </Card>
                  </Col>
                  :
                  <Col>
                    <Row justify="center">{stats()}</Row>
                    <Row justify="center">
                      <Card className="mainCard" >
                        <Row justify="center">
                          {props.login.memberType === "business" ? (
                            <Button
                              type="primary"
                              shape="round"
                              onClick={() => openIndexDrawer(null)}
                            >
                              <PlusOutlined /> Add new index
                            </Button>
                          ) : (
                            <></>
                          )}
                        </Row>
                        <Row justify="center">
                          <Tabs
                            defaultActiveKey={defaultTabKey}
                            centered>
                            {getTabs()}
                          </Tabs>
                        </Row>
                      </Card>
                    </Row>
                  </Col>
              }
            </Row>
          </div>
        </Content>

        {props.login.memberType === "business" ? (
          <IndexDrawerForm
            visible={addIndexVisible}
            loading={creatingIndex}
            onClose={closeDrawer}
            onSubmit={
              indexDrawerData ? updateCurrentIndex : submitNewIndex
            }
            indexData={indexDrawerData}
            title={indexDrawerData ? `Update document index` : null}
          />
        ) : (
          <></>
        )}
        <Footer className={"copyright"} >
          ChainRecord ©{new Date().getFullYear()}
          <div className="version">{`${config.projectCommitDate} ${config.projectCommitShortSHA}`}</div>
        </Footer>
      </Layout>
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    login: state.loginReducer,
    indexData: state.documentIndexReducer.indexData
  }
};

const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(logOut()),
  updateDocumentIndex: updateDocumentIndexAction(dispatch),
  addDocumentIndex: addDocumentIndexAction(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
