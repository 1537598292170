import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Divider,
  Select,
  Tabs,
  Col,
  Row,
  Form,
  Input,
  Button,
  Card,
  Modal,
  Table,
} from "antd";
import "./index.scss"
import { CarOutlined } from "@ant-design/icons";
import IndexDescription from "../IndexDescription/IndexDescription";
import { SearchOutlined } from "@ant-design/icons";
import { COUNTRY_CODES } from "../../../store/consts";
import { clearDocumentIndexAction, findDocumentIndexAction } from "../../../store/modules/documentIndex/actions";
const { TabPane } = Tabs;
const { Option } = Select;

const cardStyle = {
  margin: 8,
  minWidth: "45vw",
  maxWidth: "70vw",
};

const row: any = {
  align: "middle",
  justify: "start",
  type: "flex",
  gutter: [8],
  style: {
    width: "100%",
  },
};

// const dataSource = [
//   {
//     key: '1',
//     name: 'Mike',
//     age: 32,
//     address: '10 Downing Street',
//   },
//   {
//     key: '2',
//     name: 'John',
//     age: 42,
//     address: '10 Downing Street',
//   },
// ];

const columns = [
  {
    title: 'Participant',
    dataIndex: 'participant',
    key: 'participant',
  },
  {
    title: 'Document',
    dataIndex: 'document',
    key: 'document',
  },
  {
    title: 'Process',
    dataIndex: 'process',
    key: 'process',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
  },
];

const FindIndexTab = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [resultsTableData, setResultsTableData] = useState([]);

  useEffect(() => {
    calculateDocumentResults()
  }, [props.findIndexDataResult])

  const calculateDocumentResults = () => {
    setResultsTableData([])
    if (!props.findIndexDataResult || !Array.isArray(props.findIndexDataResult)) return
    const participantDocumentProcess = props.findIndexDataResult
      .map(index => ({
        participant: index.participantIdentifier.value,
        document: index.documentIdentifier.scheme.value,
        process: index.processIdentifier.value,
      }))
      .map((item) => ({
        ...item,
        key: `${item.participant}${item.document}${item.process}`
      }))

    const result = Object
      .entries(participantDocumentProcess
        .reduce((uniqueValuesMap, currentValue) => {
          if (uniqueValuesMap[currentValue.key]) return uniqueValuesMap

          uniqueValuesMap[currentValue.key] = {
            ...currentValue,
            quantity: participantDocumentProcess
              .filter(item => item.key === currentValue.key)
              .length
          }

          return uniqueValuesMap
        }, {}))
      .map(([_, value]) => value)

    setResultsTableData(result)
  }

  const onFindIndex = async (values) => {
    setLoading(true);
    try {
      await props.findDocumentIndex(
        props.country,
        values.truck_reg_number,
        values.transport_country_code
      )
    } catch (err) {
      setLoading(false);
      if (err.response && err.response.status == 404) {
        Modal.warning({
          centered: true,
          title: "Search failed!",
          content: <div>Index not found</div>,
        });
      } else if (err.response && err.response.status == 401) {
        Modal.error({
          centered: true,
          title: "Session ended",
          content: <div>Your session has ended. Please login again.</div>,
          onOk: () => {
            window.location.reload();
          },
        });
        return;
      } else {
        Modal.error({
          centered: true,
          title: "Search failed!",
          content: <div>{err.toString()}</div>,
        });
      }
      return;
    }

    setLoading(false);
  };

  return (
    <TabPane className="FindIndexTab" {...props}>
      <Card style={{ ...cardStyle }}>
        <Form
          form={form}
          // style={{ flexGrow: 1, width: "100%", height: "100%" }}
          name="horizontal_login"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          //   layout="horizontal"
          onFinish={onFindIndex}
        >
          <Row {...row}>
            <Col span={24}>
              <Form.Item
                labelAlign={'left'}
                label="Truck Registration Number"
                name="truck_reg_number"
                rules={[
                  {
                    required: true,
                    message:
                      "Please input truck registration number (ex. 800ABC) !",
                  },
                ]}
              >
                <Input
                  //   style={{ width: "100%" }}
                  prefix={<CarOutlined className="site-form-item-icon" />}
                //   placeholder="Please write truck reg. number here"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row {...row}>
            <Col span={24}>
              <Form.Item
                labelAlign={'left'}
                label="Transport Country Code"
                name="transport_country_code"
                rules={[
                  {
                    required: true,
                    message: "Please select transport country code!",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Please select"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {COUNTRY_CODES.map((c) => (
                    <Option value={c.toLowerCase()}>{c.toUpperCase()}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row {...row}>
            <Col span={18}>
              <Form.Item noStyle shouldUpdate>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  icon={<SearchOutlined />}
                >
                  Search
                </Button>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Button
                style={{ width: "100%" }}
                onClick={() => {
                  form.resetFields();
                  props.clearDocumentIndex();
                }}
              >
                Clear
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      {
        !!resultsTableData.length &&
        <Card style={cardStyle}>
          <Table
            dataSource={resultsTableData}
            columns={columns}
            pagination={false} />
            <div className="resultsTotal" >
              <span>Total:</span>{props.findIndexDataResult.length}
            </div>
        </Card>
      }
      <Card style={cardStyle}>
        <Row
          // layout="flex"
          justify="center"
          align="middle">
          <IndexDescription
            loading={loading}
            data={props.findIndexDataResult}
            onViewData={() => {
              Modal.info({
                centered: true,
                width: "55vw",
                title: "JSON data",
                content: JSON.stringify(props.findIndexDataResult),
                onOk() { },
              });
            }}
          />
        </Row>
      </Card>
    </TabPane>
  );
};

const mapStateToProps = (state) => {
  return {
    country: state.loginReducer.country,
    findIndexDataResult: state.documentIndexReducer.findIndexDataResult
  }
};

const mapDispatchToProps = (dispatch) => ({
  findDocumentIndex: findDocumentIndexAction(dispatch),
  clearDocumentIndex: clearDocumentIndexAction(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FindIndexTab);
