import { setAccessToken } from "./instance";
import { instance } from "./instance";

export const uploadForSigning = (file: IFile) => instance
    .post(`/file/upload.json`, {
        "file": file
    });

export const checkFileUploadStatus = (fileToken) => instance
    .get(`/file/upload/${fileToken}/status.json`)

export const createSigningPage = (signRequest: ISignRequest) => {
    setAccessToken(localStorage.getItem('access_token'))
    return instance
        .post(`/signing/create.json`, signRequest)
}

export const deleteSignedDocument = (signingToken: string) => instance
    .get(`/api/signing/${signingToken}/delete.json`)

export const downloadSignedDocument = (file: string) => instance
    .post(`/file/download.json`, { file })

interface IFile {
    name: String,
    digest: String,
    url: String
}

interface ISigner {
    id: String | Number,
    name: String,
    surname: String,
    signing_purpose?: String
}

interface IFileSignRequest {
    token: String
}

interface ISignRequest {
    signRequest: {
        type: String,
        name: String,
        signers: ISigner[],
        files: IFileSignRequest[]
        postback_url?: string;
    },
    documentMeta: any
}