import React, { useState } from "react";
import {
    Input,
    Spin,
    Modal,
    message,
    Button,
} from 'antd';
import './index.scss';
import {
    PlusOutlined,
    UserDeleteOutlined,
} from '@ant-design/icons';
import { connect } from "react-redux";
import { checkFileUploadStatus, createSigningPage, uploadForSigning } from "../../../services/dokobit/document";
import config from "../../../config";
import { getDocumentIndexAction, getFileAccessAction, getFileMetadataAction } from "../../../store/modules/documentIndex/actions";
import { parseFileIdFromUri } from "../../utils/fileAccess";

const ModalSelectSigners = (props) => {
    const [editAddUserValue, setEditAddUserValue] = useState('');
    const [isAddingUser, setIsAddingUser] = useState(false);
    const [signers, setSigners] = useState([]);

    const signDocument = async () => {
        const { fileMetadata } = props

        const accessToken = localStorage.getItem("access_token");
        const file = {
            name: fileMetadata.name,
            digest: fileMetadata.sha256,
            // TODO: make dynamic url
            url: `https://middleware.cloud.chainrecord.net/api/storage/v1/files/${fileMetadata.id}/content?accessToken=${accessToken}`
        }
        try {
            const { data: { token } } = await uploadForSigning(file)

            await new Promise((resolve) => setTimeout(() => {
                resolve('')
            }, 15000))

            await checkFileUploadStatus(token)

            const { data: signingData } = await createSigningPage({
                signRequest: {
                    type: "pdf",
                    name: "agreement",
                    signers: signers.map(signer => ({
                        id: signer,
                        name: signer,
                        surname: signer,
                        signing_purpose: "signature"
                    })),
                    files: [{ token }],
                    postback_url: `${config.middlewareUrl}/api/dokobit/v1/signing/postback`
                },
                documentMeta: {
                    fileId: fileMetadata.id,
                    country: props.country,
                    indexId: props.indexId
                }
            })
            message.success({
                content: "Sign request successfully sent",
                duration: 3,
            });

        } catch (err) {
            message.error({
                content: `${err.message}. Try again later.`,
                duration: 5,
            });
        }
    }

    const getManagableAccess = (type, userId?: string) => {
        const access = props.items
            .filter(({ policies }) => {
                return policies
                    .filter(({ resources }) => !!resources
                        .find((resource) => (resource.indexOf(type) > -1 ||
                            resource.indexOf('<.*>') > -1))).length > 0
            })
            .map(({ policies, userID }) => {
                return {
                    userID,
                    actions: policies
                        .filter(({ resources }) => !!resources
                            .find((resource) => (resource.indexOf(type) > -1 ||
                                resource.indexOf('<.*>') > -1)))
                        .flatMap(({ actions }) => actions),
                    accessActions: policies
                        .filter(({ resources }) => !!resources
                            .find((resource) => (resource.indexOf('access') > -1 ||
                                resource.indexOf('<.*>') > -1)))
                        .flatMap(({ actions }) => actions),
                    allUserAccess: !userId && userID ? {
                        metadata: getManagableAccess("metadata", userID),
                        content: getManagableAccess("content", userID),
                        access: getManagableAccess("access", userID)
                    } : null
                }
            })
            .filter(({ userID }) => (userId === userID || userId == null))
        if (userId) {
            return access.length ? access[0] : { actions: [] }
        }
        return access
    }

    const sendSignatureRequest = async () => {
        setIsAddingUser(true)
        await signDocument()
        await props.getDocumentIndex(props.country, props.indexId)
        await Promise.all([
            props.getFileAccess(props.fileId),
            props.getFileMetadata(props.fileId)
        ])
        closeAddUserModal()
        setIsAddingUser(false)
        setSigners([])
        setEditAddUserValue('')
    }

    const closeAddUserModal = () => {
        props.setModalVisible(false)
        const element: any = document!.getElementsByTagName("BODY")[0]
        element.style = ''
    }

    return (
        <Modal
            title="Sign request"
            visible={props.visible}
            onOk={sendSignatureRequest}
            okButtonProps={{ disabled: !signers.length || isAddingUser }}
            cancelButtonProps={{ disabled: isAddingUser }}
            maskClosable={false}
            onCancel={closeAddUserModal} >
            <div className="addUserModal">
                <h3 style={{ marginBottom: "0.75rem" }} >Signers</h3>
                {
                    signers.map((signer) => (
                        <div className="addUserInput" >
                            <Input
                                value={signer}
                                placeholder="Signer Id"
                                disabled
                                key={signer} />
                            <Button
                                type="primary"
                                shape="circle"
                                onClick={() => {
                                    setSigners(signers
                                        .filter((signerCurrent) => signerCurrent !== signer))
                                }}
                                icon={<UserDeleteOutlined />} />
                        </div>
                    ))
                }
                <div className="addUserInput" >
                    <Input
                        value={editAddUserValue}
                        placeholder="Signer Id"
                        onChange={(ev) => setEditAddUserValue(ev.target.value)} />
                    <Button
                        type="primary"
                        shape="circle"
                        onClick={() => {
                            // if (!editAddUserValue || !editAddUserValue.trim()) return
                            setSigners([...signers, editAddUserValue])
                            setEditAddUserValue('')
                        }}
                        icon={<PlusOutlined />} />
                </div>
                {
                    isAddingUser &&
                    <div className="accessSpinner">
                        <Spin tip={"Generating sign request..."} size={"small"} />
                    </div>
                }
            </div>
        </Modal >
    );
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
    getDocumentIndex: getDocumentIndexAction(dispatch),
    getFileAccess: getFileAccessAction(dispatch),
    getFileMetadata: getFileMetadataAction(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalSelectSigners);